import { useParams } from 'react-router-dom'
import { Menu } from '../../components/menu'
import { Link } from '../../components/link'
import { Button } from '../../@beegin/react/components/Button'
import { useVoltar } from '../../hooks/useVoltar'
import { Flex } from '../../components/Flex'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { FormEvent, useState, useEffect } from 'react'
import { Input } from '../../@beegin/react/components/Input'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { copiarParaAreaDeTransferencia } from '../../utils/copiarParaAreaDeTransferencia'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useUsuario } from '../../store/usuario'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import { usuariosApi } from '../../services/api'

export function EditarUsuarioLogin(): JSX.Element {
  let { usuarioId } = useParams()

  const { data: usuarioData, isLoading } = useUsuario(usuarioId)
  const [usuario, setUsuario] = useState(usuarioData)
  const [deletedOn, setDeletedOn] = useState('')

  const voltar = useVoltar(`/usuarios/${usuarioId}`)

  useEffect(() => {
    if (usuarioData) {
      setUsuario(usuarioData)
    }
  }, [usuarioData])

  function handleDateChange(e: React.ChangeEvent<HTMLInputElement>) {
    let value = e.target.value
    value = value.replace(/\D/g, '') // Remove caracteres não numéricos
    if (value.length >= 3 && value.length <= 4) {
      value = value.replace(/^(\d{2})(\d{1,2})/, '$1/$2')
    } else if (value.length > 4) {
      value = value.replace(/^(\d{2})(\d{2})(\d{1,4})/, '$1/$2/$3')
    }
    setDeletedOn(value)
  }

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    try {
      if (!usuario) {
        toast.error('Usuário não encontrado')
        return
      }
      if (usuario?.deletedOn) {
        // Desbloquear usuário
        const response = await usuariosApi.post(`/usuarios/${usuarioId}/desbloquear`)
        if (response.status !== 200) {
          toast.error('Erro ao desbloquear usuário')
          return
        }
        toast.success('Usuário desbloqueado com sucesso!')
        setUsuario({ ...usuario, deletedOn: null, deletedByUserId: null })
      } else {
        // Bloquear usuário
        const response = await usuariosApi.post(`/usuarios/${usuarioId}/bloquear`, { deletedOn: dayjs(deletedOn, 'DD/MM/YYYY').toISOString() })

        if (response.status !== 200) {
          toast.error('Erro ao bloquear usuário')
          return
        }

        toast.success('Usuário bloqueado com sucesso!')
        setUsuario({ ...usuario, deletedOn: dayjs(deletedOn, 'DD/MM/YYYY').toISOString(), deletedByUserId: 'admin' })
      }
    } catch (error) {
      toast.error('Erro ao atualizar usuário')
    }
  }

  function copiarValor(valor: string) {
    copiarParaAreaDeTransferencia(valor)
    toast.success('Copiado para área de transferência!')
  }

  const header = () => {
    return (
      <>
        <Flex gap='8px' margin='0 0 4px 0'>
          <Link to={voltar}>
            <Button variant='outlined'>👈 Voltar</Button>
          </Link>
        </Flex>
      </>
    )
  }

  if (isLoading) {
    return (
      <Menu>
        {header()}
        <PilhaDeItens gap={3} margin='0 0 32px 0' maxWidth='600px'>
          <Esqueleto height='56px' />
          <Esqueleto height='56px' />
          <Esqueleto height='36px' width='120px' />
        </PilhaDeItens>
      </Menu>
    )
  }

  return (
    <Menu>
      <>
        {header()}
        <h1>Dados de Login do Usuário</h1>
        <form onSubmit={onSubmit}>
          <PilhaDeItens gap={3} margin='0 0 32px 0' maxWidth='600px'>
            <Input value={usuario?.id ?? ''} label='ID' disabled />
            <Input value={usuario?.nomeCompleto ?? ''} label='Nome' disabled />
            <Input label='E-mail' value={usuario?.email ?? ''} disabled />
            {usuario?.deletedOn ? (
              <>
                <Input label='Data de Bloqueio' value={dayjs(usuario.deletedOn).format('DD/MM/YYYY')} disabled />
                <Input label='Deletado Por' value={usuario.deletedByUserId ?? ''} disabled />
                <Button type='submit' variant='contained' color='primary'>
                  Desbloquear
                </Button>
              </>
            ) : (
              <>
                <Input
                  label='Data de Bloqueio'
                  value={deletedOn}
                  onChange={handleDateChange}
                  placeholder='dd/mm/YYYY'
                />
                <Button type='submit' variant='contained' color='secondary'>
                  Bloquear
                </Button>
              </>
            )}
          </PilhaDeItens>
        </form>
      </>
    </Menu>
  )
}
